import { Controller } from '@hotwired/stimulus'
import { navigator } from '@hotwired/turbo'

export default class extends Controller {
  static targets = ['submit', 'input']

  readonly submitTarget: HTMLButtonElement
  readonly hasSubmitTarget: boolean
  readonly inputTargets: HTMLElement[]

  connect() {
    this.validate()
  }

  validate() {
    if (this.hasSubmitTarget) {
      setTimeout(() => {
        if (this.isAutofilled()) {
          this.submitTarget.disabled = false
        }
      }, 60)
    }
  }

  isAutofilled(): boolean {
    return this.inputTargets.every(
      element => element.matches(':-webkit-autofill') === true
    )
  }
}
