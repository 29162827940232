import '@hotwired/turbo-rails'
import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'

import '~/shared/font.js'
import '~/auth/styles/auth.css'

// init Stimulus
const app = Application.start()
console.log('Creyos ⚡️ 🧠')

// import shared controllers
const sharedControllers = import.meta.globEager(
  '../shared/controllers/**/*_controller.{ts,js}'
)
registerControllers(app, sharedControllers)

const authControllers = import.meta.globEager(
  '../auth/controllers/**/*_controller.{ts,js}'
)
registerControllers(app, authControllers)
